<messages>["./Configuration"]</messages>

<!--
================================================================================
  Template
================================================================================
-->

<template>
  <base-layout mw2>
    <v-col cols="12">
      <v-alert
        id="error"
        transition="slide-y-transition"
        type="error"
        :value="error">
        <pre class="inlineCode">{{ errorMsg }}</pre>
      </v-alert>
      <v-card>
        <v-card-title primary-title>
          <div>
            <div
              v-t="'registries.create.title'"
              class="text-h5"/>
            <div
              v-t="'registries.create.subTitle'"
              class="cgwng-subheading"/>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                v-model="initialUse"
                :items="useItems"
                :label="$t ('registries.create.use')"/>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="comment"
                name="comment"
                :label="$t ('registries.create.comment')"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div
                class="text-subtitle-1"
                v-text="$t ('registries.details.title')"/>
            </v-col>
            <v-col cols="12">
              <xml
                v-model="config"
                close-tags
                auto-grow
                :lines="10"/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <floating-button
            color="primary"
            :l-offset="2"
            :loading="isLoadingSave"
            @click="onStore">
            {{ $t ('general.button.save') }}
          </floating-button>
          <v-spacer/>
          <v-btn
            v-t="'general.button.reset'"
            text
            @click="onReset"/>
        </v-card-actions>
      </v-card>
    </v-col>

    <confirmation-dialog
      v-model="showErrorDialog"
      :headline="$t ('registries.error.title')"
      @ok="scrollToErrorMessage">
      <span v-t="'registries.error.text'"/>
    </confirmation-dialog>
  </base-layout>
</template>

<!--
================================================================================
  Logic
================================================================================
-->

<script>
  import {mapActions, mapGetters, mapMutations} from 'vuex'
  import goTo from 'vuetify/lib/services/goto'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'
  import FloatingButton from '@/app/core/components/FloatingButton'
  import Xml from '@/app/core/components/CodeEditor/Xml'
  import {getDefaultCreateParams} from './components/constants'

  import ContentHeightReporter from '@/app/core/mixins/ContentHeightReporter'

  import errorTranslator from '@/app/services/errortranslator'

  export default {
    name: 'RegistryCreate',

    components: {
      BaseLayout,
      ConfirmationDialog,
      FloatingButton,
      Xml
    },

    mixins: [ContentHeightReporter],

    data () {
      return {
        isLoading: true,
        isLoadingSave: false,
        error: false,
        showErrorDialog: false,
        errorMsg: '',
        config: '',
        initialUse: 4,
        comment: ''
      }
    },

    computed: {
      ...mapGetters ('create', [
        'getRegistryCreateData'
      ]),

      useItems () {
        return [0, 1, 2, 3, 4].map (
          i => ({value: i, text: this.$t (`registries.use.${i}`)}))
      }
    },

    watch: {
      config (newValue) {
        this.setRegistryCreateData ({
          ...this.getRegistryCreateData,
          config: newValue
        })
      },

      comment (newValue) {
        this.setRegistryCreateData ({
          ...this.getRegistryCreateData,
          comment: newValue
        })
      },

      initialUse (newValue) {
        this.setRegistryCreateData ({
          ...this.getRegistryCreateData,
          initialUse: newValue
        })
      },

      error (newValue) {
        if (newValue) this.showErrorDialog = true
      }
    },

    created () {
      const data = this.getRegistryCreateData
      this.config = data.config
      this.comment = data.comment
      this.initialUse = data.initialUse
    },

    methods: {
      ...mapMutations ('notification', ['addEvent']),

      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess',
        setRegistryCreateData: 'create/setRegistryCreateData'
      }),

      ...mapActions ({
        fetchData: 'request/fetchData',
        clearCache: 'cache/clearRegistryTypeDataCache'
      }),

      onStore () {
        this.isLoadingSave = true
        this.error = false
        this.fetchData ({
          op: 'registry/config/create',
          params: {
            content: this.config,
            use: this.initialUse,
            comment: this.comment
          },
          cb: () => {
            this.displaySuccessMessage (
              this.$t ('registries.details.saved'))

            this.addEvent ({
              type: 'success',
              message: this.$t ('registries.details.saved'),
              objects: [{name: 'Registry', link: {name: 'config.registries'}}]
            })

            this.error = false
            this.setRegistryCreateData (getDefaultCreateParams ())
            this.$router.push ({name: 'config.registries'})
            this.clearCache ()
          },
          cbError: data => {
            this.error = true
            this.errorMsg = data.errorData || errorTranslator (data)
          },
          cbFinal: () => {
            this.isLoadingSave = false
          }
        })
      },

      onReset () {
        this.error = false
        this.config = ''
        this.comment = ''
        this.initialUse = 4
      },

      scrollToErrorMessage () {
        goTo ('#error')
        this.showErrorDialog = false
      }
    }
  }
</script>
